@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/magnific-popup/src/css/main";

html {
	background: $color-rusty-red url('../img/bg/HalfAlive_CoverResize_Desktop.png') no-repeat center top;
	background-size: cover;
	font-family: "Helvetica LT W05 Roman", sans-serif;
	font-size: 16px;
	line-height: 1;
	text-align: center;

	@include tablet-down {
		background-image: url('../img/bg/HalfAlive_CoverResize_Mobile.jpg');
	}
}

body {
	align-items: flex-start;
	color: $color-white;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	min-height: 100vh;
}

header, main, footer {
	width: 100%;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	flex: 0 1 auto;
	padding: 20px 0 40px 0;

	h1 {
		margin: 0 auto;

		> img {
			margin: 0 auto;
			width: 185px;
		}
	}
}

main {
	flex: 1 1 auto;

	h2 {
		margin: 0 auto 10px auto;

		> img {
			margin: 0 auto;
			width: 144px;
		}
	}

	h3 {
		margin: 0 auto 40px auto;

		> img {
			margin: 0 auto;
			width: 144px;
		}
	}

	.video {
		margin: 0 auto 40px auto;
		max-width: 400px;
	}

	.links {
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			> a {
				display: inline-block;
				max-width: 200px;
				transform: scale(1);
				transition: all 250ms linear;
				vertical-align: middle;

				@include hover-focus {
					transform: scale(1.1);
				}
			}

			+ li {
				margin-top: 10px;
			}
		}
	}

	.disclaimer {
		font-size: 8px;
		margin: 10px 0 0 0;
	}
}

footer {
	flex: 0 1 auto;
	padding: 20px 0;

	.follow {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;

		@include phone-down {
			flex-direction: column-reverse;
		}

		> div {
			flex: 0 0 auto;

			&:first-child {
				> .socials {
					list-style: none;
					margin: 0;
					padding: 0;

					> li {
						display: inline-block;
						font-size: 13px;
						vertical-align: middle;

						+ li {
							margin-left: 5px;
						}

						> a {
							color: $color-white;

							@include hover-focus {
								color: $color-rusty-red;
							}
						}
					}
				}
			}

			&:last-child {
				margin-left: 10px;

				@include phone-down {
					margin-bottom: 20px;
					margin-left: 0;
				}

				> form {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;

					> input {
						background: none;
						border: 0;
						border-bottom: 2px solid $color-white;
						color: $color-white;
						display: block;
						font-size: 12px;
						letter-spacing: 0.16px;
						line-height: 1;
						padding: 0 0 1px 0;
						text-align: center;
						width: 114px;

						@include placeholder {
							color: $color-white;
							text-transform: lowercase;
						}

						&:focus {
							border-color: $color-rusty-red;
							outline: 0;
						}
					}

					> button {
						background: $color-white;
						border: 0;
						color: $color-rusty-red;
						display: inline-block;
						font-size: 12px;
						line-height: 1;
						margin-left: 5px;
						padding: 5px 10px;
						text-align: center;
						text-transform: lowercase;
						vertical-align: middle;

						@include hover-focus {
							background: $color-rusty-red;
							color: $color-white;
						}
					}

					> .thanks {
						font-size: 12px;
						text-transform: lowercase;
					}
				}
			}
		}
	}

	.enter {
		margin: 20px 0 40px 0;

		> a {
			background: $color-white;
			border-radius: 25px;
			color: $color-rusty-red;
			display: inline-block;
			font-size: 18px;
			min-width: 100px;
			padding: 15px 25px;
			text-align: center;
			text-transform: lowercase;
			vertical-align: middle;

			@include hover-focus {
				background: $color-rusty-red;
				color: $color-white;
			}
		}
	}

	.copyright {
		font-size: 8px;
		line-height: 1;
		margin: 0 auto;
		text-transform: uppercase;
		width: 400px;

		p {
			margin: 0;

			a {
				@include hover-focus {
					text-decoration: underline;
				}
			}
		}
	}
}

.popup {
	background: $color-rusty-red;
	border: 2px solid $color-white;
	margin: 20px auto;
	max-width: rem(500);
	padding: rem(20);
	position: relative;
	text-align: center;
	width: auto;

	.mfp-close {
		color: $color-white;
	}

	> form {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;

		> input {
			background: none;
			border: 0;
			border-bottom: 2px solid $color-white;
			color: $color-white;
			display: block;
			font-size: 8px;
			letter-spacing: 0.16px;
			line-height: 1;
			padding: 0 0 1px 0;
			text-align: center;
			width: 114px;

			@include placeholder {
				color: $color-white;
				text-transform: lowercase;
			}

			&:focus {
				border-color: $color-rusty-red;
				outline: 0;
			}
		}

		> button {
			background: $color-white;
			border: 0;
			color: $color-rusty-red;
			display: inline-block;
			font-size: 10px;
			line-height: 1;
			margin-left: 5px;
			padding: 5px 10px;
			text-align: center;
			text-transform: lowercase;
			vertical-align: middle;

			@include hover-focus {
				background: $color-rusty-red;
				color: $color-white;
			}
		}
	}
}
